import { GuideIndice } from "@/components/Guide/Indice";
import { SelectOption } from "@/components/Inputs/FormInputSelect";

// Locations data containing geographic coordinates of various cities
export const LOCATIONS = {
  BUENOS_AIRES: "-34.57586359329449,-58.43315924940862",
  BARILOCHE: "-41.145434341065155,-71.16423559563788",
  MENDOZA: "-32.827101109568765,-68.80413097807634",
  TUCUMAN: "-26.83643996487673,-65.09916078173788",
  CORDOBA: "-31.4201,-64.1888",
  SANTA_FE: "-31.6333,-60.7000",
  ENTRE_RIOS: "-31.7382,-60.5238",
  SALTA: "-24.7829,-65.4125",
  CHACO: "-27.4514,-58.9866",
  MISIONES: "-27.3621,-55.9009",
  CORRIENTES: "-27.4806,-58.8341",
  SANTIAGO_DEL_ESTERO: "-27.7951,-64.2615",
  SAN_JUAN: "-31.5375,-68.5364",
  JUJUY: "-24.1858,-65.2995",
  RIO_NEGRO: "-40.8135,-62.9967",
  FORMOSA: "-26.1831,-58.1734",
  NEUQUEN: "-38.9516,-68.0591",
  CHUBUT: "-43.3002,-65.1023",
  SAN_LUIS: "-33.2950,-66.3356",
  CATAMARCA: "-28.4696,-65.7852",
  LA_RIOJA: "-29.4131,-66.8558",
  LA_PAMPA: "-36.6177,-64.2860",
  SANTA_CRUZ: "-51.6230,-69.2168",
  TIERRA_DEL_FUEGO: "-54.8019,-68.3020",
};
// List of branch offices with city names, images, and coordinates
export const SUCURSALS = [
  {
    city: "Buenos Aires",
    image: "/palermo.svg",
    coords: LOCATIONS.BUENOS_AIRES,
  },
  {
    city: "Mendoza",
    image: "/mendoza.svg",
    coords: LOCATIONS.MENDOZA,
  },
  {
    city: "Bariloche",
    image: "/bariloche.svg",
    coords: LOCATIONS.BARILOCHE,
  },
  {
    city: "Tucumán",
    image: "tucuman.svg",
    coords: LOCATIONS.TUCUMAN,
  },
  {
    city: "Córdoba",
    image: "/cordoba.svg",
    coords: LOCATIONS.CORDOBA,
  },
  {
    city: "Santa Fe",
    image: "/santafe.svg",
    coords: LOCATIONS.SANTA_FE,
  },
  {
    city: "Salta",
    image: "/salta.svg",
    coords: LOCATIONS.SALTA,
  },
  {
    city: "Misiones",
    image: "/misiones.svg",
    coords: LOCATIONS.MISIONES,
  },
  {
    city: "Corrientes",
    image: "/corrientes.svg",
    coords: LOCATIONS.CORRIENTES,
  },
  {
    city: "Jujuy",
    image: "/jujuy.svg",
    coords: LOCATIONS.JUJUY,
  },
  {
    city: "Neuquén",
    image: "/neuquen.svg",
    coords: LOCATIONS.NEUQUEN,
  },
  // {
  //   city: "Tierra del fuego",
  //   image: "/tierradelfuego.svg",
  //   coords: LOCATIONS.TIERRA_DEL_FUEGO,
  // },
  // {
  //   city: "Chaco",
  //   image: "/chaco.svg",
  //   coords: LOCATIONS.CHACO,
  // },
  // {
  //   city: "Entre Ríos",
  //   image: "/entrerios.svg",
  //   coords: LOCATIONS.ENTRE_RIOS,
  // },
  // {
  //   city: "Santiago del Estero",
  //   image: "/santiagodelestero.svg",
  //   coords: LOCATIONS.SANTIAGO_DEL_ESTERO,
  // },
  // {
  //   city: "San Juan",
  //   image: "/sanjuan.svg",
  //   coords: LOCATIONS.SAN_JUAN,
  // },
  // {
  //   city: "Rio Negro",
  //   image: "/rionegro.svg",
  //   coords: LOCATIONS.RIO_NEGRO,
  // },
  // {
  //   city: "Formosa",
  //   image: "/formosa.svg",
  //   coords: LOCATIONS.FORMOSA,
  // },
  // {
  //   city: "Chubut",
  //   image: "/chubut.svg",
  //   coords: LOCATIONS.CHUBUT,
  // },
  // {
  //   city: "San Luis",
  //   image: "/sanluis.svg",
  //   coords: LOCATIONS.SAN_LUIS,
  // },
  // {
  //   city: "Catamarca",
  //   image: "/catamarca.svg",
  //   coords: LOCATIONS.CATAMARCA,
  // },
  // {
  //   city: "La Rioja",
  //   image: "/larioja.svg",
  //   coords: LOCATIONS.LA_RIOJA,
  // },

  // {
  //   city: "La Pampa",
  //   image: "/lapampa.svg",
  //   coords: LOCATIONS.LA_PAMPA,
  // },
  // {
  //   city: "Santa Cruz",
  //   image: "/santacruz.svg",
  //   coords: LOCATIONS.SANTA_CRUZ,
  // },
];
// List of user reviews containing names, photos, and reviews
export const USERS_REVIEWS = [
  {
    userName: "Fabricio S.",
    photo: "fabricio.webp",
    review:
      "Excelente experiencia, resulta rentable el negocio, gracias a lo cual pudimos salir adelante con mi familia, todo funciona conforme a lo acordado. Muy recomendable la inversión.",
  },
  {
    userName: "Roberto A.",
    photo: "roberto.webp",
    review:
      "Plenamente satisfecho con mi visión de seguir apostando a ATUA Rentacar con más unidades!",
  },
  {
    userName: "Hugo L.",
    photo: "hugo.webp",
    review:
      "Soy jubilado y con mi esposa decidimos rentar nuestro auto para mejorar nuestros ingresos, realmente estamos muy satisfechos, cumplen con lo pactado en tiempo y forma. Nos da una tranquilidad absoluta.",
  },
  {
    userName: "Gabriel L.",
    photo: "gabriel.webp",
    review:
      "Excelente servicio y atención, cumplen con todo lo pactado, cuentan con muy buenas unidades en alquiler, muy recomendables.",
  },
  {
    userName: "Osmar",
    photo: "osmar.webp",
    review:
      "Muy contento con la renta que me genera ATUA. Es muy responsable y de fiar. Estaba acostumbrado a la renta de un mono ambiente de $25000 y solo un auto me genera eso. Tengo 4 autos en la flota y con ganas de seguir creciendo junto a ATUA.",
  },
];
// Enum representing different reservation status types
export enum RESERVATION_STATUS {
  VOIDED = "PENDIENTE DE CONFIRMACION",
  CONFIRMED = "CONFIRMADA",
  IN_COLLECTION = "EN RECAUDO",
  TICKETED = "FACTURADA",
  CHECKIN = "CHECKIN",
  CHECKOUT = "CHECKOUT",
  COMPLETED = "COMPLETADA",
  CANCELED = "CANCELADA",
}

export const IDENTIFICATION_TYPES: SelectOption[] = [
  {
    value: "DNI",
    label: "Documento nacional de identidad",
  },
  {
    value: "CE",
    label: "Cédula extranjera",
  },
  {
    value: "PA",
    label: "Pasaporte",
  },
];

export const ORDER_PRICE: SelectOption[] = [
  {
    value: 1,
    label: "Precio por día: menor a mayor",
  },
  {
    value: 2,
    label: "Precio por día: mayor a menor",
  },
];

export const ENERGY_TYPES: SelectOption[] = [
  {
    value: "DIESEL",
    label: "Diesel",
  },
  {
    value: "ELECTRIC",
    label: "Eléctrico",
  },
  {
    value: "GASOLINE",
    label: "Gasolina",
  },
  {
    value: "HYBRID",
    label: "Híbrido",
  },
];

export const TRANSMISSION_TYPES: SelectOption[] = [
  {
    value: "AUTOMATIC",
    label: "Automático",
  },
  {
    value: "MANUAL",
    label: "Manual",
  },
];

export const SEATS_NUMBER: SelectOption[] = [
  {
    value: 2,
    label: "2",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
];

export const DOORS_NUMBER: SelectOption[] = [
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export const DAYS_WEEK: SelectOption[] = [
  {
    value: 2,
    label: "Lunes",
  },
  {
    value: 3,
    label: "Martes",
  },
  {
    value: 4,
    label: "Miércoles",
  },
  {
    value: 5,
    label: "Jueves",
  },
  {
    value: 6,
    label: "Viernes",
  },
  {
    value: 7,
    label: "Sábado",
  },
  {
    value: 8,
    label: "Domingo",
  },
];

export const POLICY_TYPE: SelectOption[] = [
  {
    value: 12,
    label: "Cancelación gratis con medio día de anticipación.",
  },
  {
    value: 24,
    label: "Cancelación gratis con 1 día de anticipación.",
  },
  {
    value: 60,
    label: "Cancelación gratis con 3 días de anticipación.",
  },
];

export const GUIDE_DRIVER_INDICE: GuideIndice[] = [
  {
    option: "1. Descarga nuestra app",
    ref: "#StepOne",
  },
  {
    option: "2. Registrate en ATUA",
    ref: "#StepTwo",
  },
  {
    option: "3. Buscá tu auto ideal",
    ref: "#StepThree",
  },
  {
    option: "4. Realiza tu reserva",
    ref: "#StepFour",
  },
  {
    option: "5. ¿Estás listo para el inicio de tu alquiler?",
    ref: "#StepFive",
  },
  {
    option: "6. Disfruta tu alquiler",
    ref: "#StepSix",
  },
  {
    option: "7. Finaliza tu alquiler",
    ref: "#StepSeven",
  },
  {
    option: "8. Después del alquiler",
    ref: "#StepEight",
  },
];

export const GUIDE_HOST_INDICE: GuideIndice[] = [
  {
    option: "1. Descarga nuestra app",
    ref: "#StepOne",
  },
  {
    option: "2. Registraté y subí tu auto",
    ref: "#StepTwo",
  },
  {
    option: "3. Mantené tu disponibilidad actualizada",
    ref: "#StepThree",
  },
  {
    option: "4. Acepta las reservas y coordina con el Conductor",
    ref: "#StepFour",
  },
  {
    option: "5. Entrega el auto",
    ref: "#StepFive",
  },
  {
    option: "6. Finaliza el alquiler",
    ref: "#StepSix",
  },
  {
    option: "7. Califica al Conductor",
    ref: "#StepSeven",
  },
  {
    option: "8. Cobros posteriores y pago",
    ref: "#StepEight",
  },
  {
    option: "9. Alquila tu auto con tranquilidad",
    ref: "#StepNine",
  },
];

export const USERS_CABIFY_REVIEWS = [
  {
    userName: "Juan Pérez.",
    photo: "placeholder.jpg",
    review:"¡Súper fácil rentar mi auto con ATUA! Atención online rápida y confiable."
  },
  {
    userName: "María Gómez",
    photo: "placeholder.jpg",
    review:
    "Al principio desconfiaba un poco, pero Hernan el conductor de mi auto es un genio."  },
  {
    userName: "Carlos Rodríguez",
    photo: "placeholder.jpg",
    review:
    "Les confié mi auto para usar en cabify y ahora estoy ganando dinero extra." },
];
